import { Directive, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { InputComponent } from '@util/components/input/input.component';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';


@Directive({
  selector: '[appInputMinLengthValidator]',
  inputs: ['appInputMinLengthValidator.useTranslationKey', 'appInputMinLengthValidator.trigger'],
  exportAs: 'appInputMinLengthValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => InputMinLengthValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => InputComponent)},
  ]
})
export class InputMinLengthValidatorDirective extends BaseValidatorDirective implements OnInit {

  protected override _thisSelector = 'appInputMinLengthValidator';
  protected override translationKey = 'validation.input.appInputMinLengthValidator';

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  private _appInputMinLengthValidator: number = 1;

  @Input()
  set appInputMinLengthValidator(value: number) {
    this._appInputMinLengthValidator = value;

    this.updateTranslation({n: value});
  }

  get appInputMinLengthValidator(): number {
    return this._appInputMinLengthValidator;
  }

  override ngOnInit() {
    super.ngOnInit();
    this.updateTranslation({n: this.appInputMinLengthValidator});

    this.ngZone.run(() => {
      this.host?.formControl?.addValidators(this.validator);

      this.host?.formControl?.updateValueAndValidity();
      this.host?.formControl?.markAsPristine();
      this.host?.formControl?.markAsUntouched();
    });
  }

  validator: ValidatorFn = (control) => {
    const errors: ValidationErrors = {'appInputMinLengthValidator': this.translation};
    const len = (control.value as string)?.length;
    return (len >= this.appInputMinLengthValidator) ? null : errors;
  };

}
