import { Directive, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { InputComponent } from '@util/components/input/input.component';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';
import { coerceBoolean } from '@util/functions/objects';



@Directive({
  selector: '[appInputRegExpCharacterValidator]',
  inputs: ['appInputRegExpCharacterValidator.useTranslationKey', 'appInputRegExpCharacterValidator.trigger'],
  exportAs: 'appInputRegExpCharacterValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => InputRegExpCharacterValidatorValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => InputComponent)}
  ]
})
export class InputRegExpCharacterValidatorValidatorDirective extends BaseValidatorDirective implements OnInit, OnDestroy {

  protected override _thisSelector = 'appInputRegExpCharacterValidator';
  protected override translationKey = 'validation.input.appInputRegExpCharacterValidator';

  private _regexpStrArr: string[];
  private _atLeastOne: boolean = true;
  private _all: boolean = false;

  @Input()
  set appInputRegExpCharacterValidator(value: string[]) {
    this._regexpStrArr = value;

    this.updateTranslation();
  }

  get appInputRegExpCharacterValidator(): string[] {
    return this._regexpStrArr;
  }

  get atLeastOne() {
    return this._atLeastOne;
  }

  @Input()
  set atLeastOne(value) {
    value = coerceBoolean(value);
    this._atLeastOne = value;
    this._all = !value;
  }

  get all() {
    return this._all;
  }

  @Input()
  set all(value: boolean) {
    value = coerceBoolean(value);
    this._atLeastOne = !value;
    this._all = value;
  }

  override ngOnInit() {
    super.ngOnInit();

    this.updateTranslation();

    this.ngZone.run(() => {
      this.host?.formControl?.addValidators(this.validator);

      this.host?.formControl?.updateValueAndValidity();
      this.host?.formControl?.markAsPristine();
      this.host?.formControl?.markAsUntouched();
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  validator: ValidatorFn = (control) => {

    const notMatchingPattern: string[] = [];
    const thisValue = control.value || '';

    (this._regexpStrArr || []).forEach(pat => {

      const regexpStr = this.atLeastOne ? pat : (pat + '*');
      const regexp = new RegExp(regexpStr || '');

      const res = regexp.exec(thisValue);

      if (this.all) {
        if (!res?.[0] || res[0].length !== res.input.length) {
          notMatchingPattern.push(pat);
        }
      }

      if (this.atLeastOne) {
        if (!res?.[0]) {
          notMatchingPattern.push(pat);
        }
      }

    });

    const unmatchedPattern = ' ' + notMatchingPattern.join(', ');
    this.updateTranslation({unmatchedPattern});

    const errors: ValidationErrors = {'appInputRegExpCharacterValidator': this.translation};
    return notMatchingPattern.length ? errors : null;

  };

}
