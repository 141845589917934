import { Directive, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { InputComponent } from '@util/components/input/input.component';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';
import { Subscription } from 'rxjs';


@Directive({
  selector: '[appInputSameAsValidator]',
  inputs: ['appInputSameAsValidator.useTranslationKey', 'appInputSameAsValidator.trigger'],
  exportAs: 'appInputSameAsValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => InputSameAsValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => InputComponent)}
  ]
})
export class InputSameAsValidatorDirective extends BaseValidatorDirective implements OnInit, OnDestroy {

  protected _thisSelector = 'appInputSameAsValidator';
  protected override translationKey = 'validation.input.appInputSameAsValidator';

  private _sameAsValueChange: Subscription;
  private _sameAs: BaseFormComponent;

  @Input()
  set appInputSameAsValidator(value: BaseFormComponent) {
    this._sameAs = value;

    this._sameAsValueChange?.unsubscribe();
    this._sameAsValueChange = this._sameAs?.formControl?.valueChanges.subscribe(val => {
      this.host?.formControl?.updateValueAndValidity();
    });

    const targetLabel = this._sameAs?.label;
    this.updateTranslation({targetLabel});
  }

  get appInputSameAsValidator(): BaseFormComponent {
    return this._sameAs;
  }

  override ngOnInit() {
    super.ngOnInit();

    const targetLabel = this._sameAs?.label;
    this.updateTranslation({targetLabel});

    this.ngZone.run(() => {
      this.host?.formControl?.addValidators(this.validator);

      this.host?.formControl?.updateValueAndValidity();
      this.host?.formControl?.markAsPristine();
      this.host?.formControl?.markAsUntouched();
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this._sameAsValueChange?.unsubscribe();
  }

  validator: ValidatorFn = (control) => {
    const targetValue = this._sameAs?.formControl?.value || '';
    const thisValue = control.value || '';
    const errors: ValidationErrors = {'appInputSameAsValidator': this.translation};
    return (this._sameAs?.formControl && thisValue === targetValue) ? null : errors;
  };

}
